import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import JsonViewer from "vue-json-viewer";
import Vuelidate from "vuelidate";
import Dialog from "./components/Dialog";
import Notification from "./components/Notification";
import Periodo from "./components/Components/Periodo";
import Color from "./components/Components/Color";

Vue.component("Dialog", Dialog);
Vue.component("Notification", Notification);
Vue.component("Periodo", Periodo);
Vue.component("Color", Color);
Vue.use(JsonViewer);
Vue.use(Vuelidate);
Vue.config.productionTip = false;

store.subscribe((mutation, state) => {
   sessionStorage.setItem("userdata", JSON.stringify(state.userdata));
});

new Vue({
   router,
   store,
   vuetify,
   beforeCreate() {
      store.commit("initializeStore");
   },
   render: (h) => h(App),
}).$mount("#app");

router.beforeEach(async function (to, from, next) {
   try {
      let authorized = false;
      let user_type = store.state.userdata.tipo;
      let route_require = to.meta.require;

      if (route_require == "S") {
         if (user_type == "S") {
            authorized = true;
         }
      } else if (route_require == "A") {
         if (user_type == "S" || user_type == "A") {
            authorized = true;
         }
      } else if (route_require == "U") {
         if (user_type == "S" || user_type == "A" || user_type == "U") {
            authorized = true;
         }
      } else if (route_require == "N") {
         authorized = true;
      }

      if (authorized == true) {
         next();
      } else {
         router.push({
            name: "Login",
            query: {
               status: "denied",
               time: new Date().getTime(),
            },
         });
      }
   } catch (error) {
      next(error);
   }
});
