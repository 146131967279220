<template>
   <v-app>
      <v-main>
         <Header />
         <router-view />
         <Footer />
      </v-main>
   </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
   components: {
      Header,
      Footer,
   },
};
</script>

<style>
::-webkit-scrollbar {
   width: 5px;
}

::-webkit-scrollbar-track {
   background: #757575;
}

::-webkit-scrollbar-thumb {
   background: #e7e7e7;
   border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
   background: #444444;
}
</style>
