import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import c from "./constant";

Vue.use(Vuex);

axios.defaults.baseURL = c.API_URL;
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

const formAxios = axios.create({
   transformRequest: [
      function (data, headers) {
         if (headers["Content-Type"] && headers["Content-Type"].startsWith("multipart/form-data")) {
            const form = new FormData();
            for (const key in data) {
               const value = data[key];
               if (Array.isArray(value)) {
                  const arrayKey = `${key}[]`;
                  value.forEach((v) => {
                     form.append(arrayKey, v);
                  });
               } else {
                  form.append(key, value);
               }
            }
            return form;
         }
         return data;
      },
   ],
});

const getCacheableState = () => {
   return {
      username: "",
      nombre: "",
      tipo: "",
      token: "",
      sucursal: "",
   };
};
const getDefaultState = () => {
   return {
      userdata: getCacheableState(),
      tipos_registros: [],
      sucursales: [],
      sucursales_combo: [],
      usuarios: [],
      registros: {
         items: [],
         totalItems: 10,
         options: {},
      },
      notificaciones: {
         items: [],
         totalItems: 10,
         options: {},
      },
   };
};

export default new Vuex.Store({
   state: getDefaultState(),
   mutations: {
      initializeStore(state) {
         if (sessionStorage.getItem("userdata")) {
            let userdata = JSON.parse(sessionStorage.getItem("userdata"));
            state.userdata = userdata;
            axios.defaults.headers.common["API-KEY"] = state.userdata.token;
         }
      },
      logout() {
         this.replaceState(getDefaultState());
      },
      login(state, payload) {
         state.userdata = payload;
      },
      tipos_registros(state, payload) {
         state.tipos_registros = payload;
      },
      sucursales(state, payload) {
         state.sucursales = payload;
      },
      sucursales_combo(state, payload) {
         state.sucursales_combo = payload;
      },
      usuarios(state, payload) {
         state.usuarios = payload;
      },
      registros(state, payload) {
         state.registros = payload;
      },
      notificaciones(state, payload) {
         state.notificaciones = payload;
      },
   },
   actions: {
      async login(context, payload) {
         try {
            let res = await axios.post("login", payload);
            context.commit("login", res.data);
            axios.defaults.headers.common["API-KEY"] = context.state.userdata.token;
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async authDevice(context, payload) {
         try {
            let res = await axios.post("auth_device", payload);
            return res.data;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //GET
      async getTiposRegistros(context) {
         try {
            let res = await axios.get("tipos_registros");
            context.commit("tipos_registros", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSucursales(context) {
         try {
            let res = await axios.get("sucursales");
            context.commit("sucursales", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSucursalesCombo(context) {
         try {
            let res = await axios.get("usuarios/sucursales");
            context.commit("sucursales_combo", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getUsuarios(context) {
         try {
            let res = await axios.get("usuarios");
            context.commit("usuarios", res.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRegistros(context, payload) {
         try {
            if (!payload) {
               payload = context.state.registros.options;
            }
            let res = await axios.get("registros", {
               params: payload,
            });
            context.commit("registros", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getNotificaciones(context, payload) {
         try {
            if (!payload) {
               payload = context.state.notificaciones.options;
            }
            let res = await axios.get("notificaciones", {
               params: payload,
            });
            context.commit("notificaciones", {
               items: res.data.items,
               totalItems: res.data.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //POST
      async insertTipoRegistro(context, payload) {
         try {
            await axios.post("tipos_registros", payload);
            context.dispatch("getTiposRegistros")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertRegistro(context, payload) {
         try {
            await axios.post("registros", payload);
            context.dispatch("getRegistros")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertNotificacion(context, payload) {
         try {
            await axios.post("notificaciones", payload);
            context.dispatch("getNotificaciones")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertSucursal(context, payload) {
         try {
            await axios.post("sucursales", payload);
            context.dispatch("getSucursales")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertUsuario(context, payload) {
         try {
            await axios.post("usuarios", payload);
            context.dispatch("getUsuarios")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //PUT
      async updateTipoRegistro(context, payload) {
         try {
            await axios.put("tipos_registros", payload);
            context.dispatch("getTiposRegistros")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateSucursal(context, payload) {
         try {
            await axios.put("sucursales", payload);
            context.dispatch("getSucursales")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateUsuario(context, payload) {
         try {
            await axios.put("usuarios", payload);
            context.dispatch("getUsuarios")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateAdminSucursales(context, payload) {
         try {
            await axios.put("admin_sucursales", payload);
            context.dispatch("getUsuarios")
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //DELETE
      async deleteRegistro(context, payload) {
         try {
            await axios.delete("registros", {
               data: payload,
            });
            context.dispatch("getRegistros");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteNotificacion(context, payload) {
         try {
            await axios.delete("notificaciones", {
               data: payload,
            });
            context.dispatch("getNotificaciones");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
   },
});
