import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

let darkt = false;
if (localStorage.getItem("dark")) {
   darkt = JSON.parse(localStorage.getItem("dark"));
}

export default new Vuetify({
   theme: {
      dark: darkt,
   },
});
