import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
   {
      path: "/",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
         require: "N",
         title: "Login",
         icon: "mdi-login",
         show: false,
      },
   },
   {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("./views/Dashboard.vue"),
      meta: {
         require: "U",
         title: "Dashboard",
         icon: "mdi-home",
         show: false,
      },
   },
   {
      path: "/tipos",
      name: "TiposRegistros",
      component: () => import("./views/TiposRegistros.vue"),
      meta: {
         require: "S",
         title: "Tipos de Registros",
         icon: "mdi-format-list-bulleted-type",
         show: true,
      },
   },
   {
      path: "/sucursales",
      name: "Sucursales",
      component: () => import("./views/Sucursales.vue"),
      meta: {
         require: "S",
         title: "Sucursales",
         icon: "mdi-store",
         show: true,
      },
   },
   {
      path: "/users",
      name: "Usuarios",
      component: () => import("./views/Usuarios.vue"),
      meta: {
         require: "A",
         title: "Usuarios",
         icon: "mdi-account-group",
         show: true,
      },
   },
   {
      path: "/state",
      name: "State",
      component: () => import("./views/State.vue"),
      meta: {
         require: "S",
         title: "State",
         icon: "mdi-code-json",
         show: false,
      },
   },
];

const router = new VueRouter({
   routes,
});

export default router;
