<template>
   <div>
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <v-app-bar app>
         <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
         <v-spacer></v-spacer>
         <v-app-bar-title>
            <v-btn elevation="0" @click="$router.push({ name: 'Dashboard' }, () => {})">
               {{ app_name }}
            </v-btn>
         </v-app-bar-title>
         <v-spacer></v-spacer>
         <div v-if="is_logged">
            <v-btn
               icon
               @click="
                  $store.commit('logout');
                  $router.push({ name: 'Login' }, () => {});
               "
            >
               <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
         </div>
         <div v-else>
            <v-btn icon @click="$router.push({ name: 'Login' }, () => {})">
               <v-icon>mdi-account-circle</v-icon>
            </v-btn>
         </div>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" fixed temporary>
         <template v-slot:prepend>
            <v-list-item two-line>
               <v-list-item-avatar>
                  <v-icon large>mdi-account-circle</v-icon>
               </v-list-item-avatar>
               <v-list-item-content>
                  <v-list-item-title>{{ $store.state.userdata.nombre }}</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$store.state.userdata.tipo == 'A' || $store.state.userdata.tipo == 'S'">
               <v-btn class="mb-2" small @click="$refs.device.show()"><v-icon small left>mdi-lock-open</v-icon> Autorizar Dispositivo</v-btn>
               <Device @save="$refs.ok_noti.Show()" ref="device" />
            </v-list-item>
         </template>
         <template v-slot:append>
            <v-list-item two-line>
               <v-list-item-avatar>
                  <v-icon large>mdi-theme-light-dark</v-icon>
               </v-list-item-avatar>
               <v-list-item-content>
                  <v-list-item-title class="px-4">
                     <v-switch inset v-model="dark" dark></v-switch>
                  </v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </template>
         <v-divider></v-divider>
         <v-list nav dense v-if="$store.state.userdata.tipo == 'A' || $store.state.userdata.tipo == 'S' || $store.state.userdata.tipo == 'U'">
            <v-list-item-group v-model="group">
               <div v-for="(item, index) in $router.options.routes" :key="index">
                  <v-list-item v-if="show_route(item)" @click="$router.push({ name: item.name }, () => {})">
                     <v-list-item-icon
                        ><v-icon dense>{{ item.meta.icon }}</v-icon></v-list-item-icon
                     >
                     <v-list-item-title>{{ item.meta.title }}</v-list-item-title>
                  </v-list-item>
               </div>
            </v-list-item-group>
         </v-list>
      </v-navigation-drawer>
   </div>
</template>

<script>
import c from "../constant";
import Device from "./Device/Device.vue";

export default {
   components: {
      Device,
   },
   computed: {
      app_name: function () {
         return c.APP_NAME;
      },
      is_logged: function () {
         if (this.$store.state.userdata.token) {
            return this.$store.state.userdata.token.length > 0;
         }
         return false;
      },
   },
   data: () => ({
      drawer: false,
      group: null,
      dark: false,
   }),
   methods: {
      show_route(item) {
         if (!item.meta.show) {
            return false;
         }
         if (item.meta.require == "S") {
            if (this.$store.state.userdata.tipo == "S") {
               return true;
            } else {
               return false;
            }
         } else if (item.meta.require == "A") {
            if (this.$store.state.userdata.tipo == "S" || this.$store.state.userdata.tipo == "A") {
               return true;
            } else {
               return false;
            }
         } else if (item.meta.require == "U") {
            if (this.$store.state.userdata.tipo == "S" || this.$store.state.userdata.tipo == "A" || this.$store.state.userdata.tipo == "U") {
               return true;
            } else {
               return false;
            }
         } else return false;
      },
   },
   watch: {
      group() {
         this.drawer = false;
      },
      dark() {
         this.$vuetify.theme.dark = this.dark;
         localStorage.setItem("dark", JSON.stringify(this.dark));
      },
   },
   created() {
      this.dark = this.$vuetify.theme.dark;
   },
};
</script>
