module.exports = Object.freeze({
   //API_URL: "http://localhost/api",
   API_URL: "public/api",
   APP_NAME: "GRUPO COMERCIAL ARDAVIS DEL NORTE",
   DEVELOP_MODE: true,
});

/**
 * S = SUPER
 * A = ADMIN
 * U = USER
 * N = NONE
 */