<template>
   <v-dialog v-model="dialog" max-width="800px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-toolbar>Autorizar este Dispositivo</v-toolbar>
         <v-card-text>
            <form>
               <v-select
                  v-model="id_sucursal"
                  label="Sucursal"
                  item-text="text"
                  item-value="value"
                  required
                  :items="$store.state.sucursales_combo"
                  :error-messages="id_sucursalErrors"
                  @input="$v.id_sucursal.$touch()"
                  @blur="$v.id_sucursal.$touch()"
               />
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="success" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
   validations: {
      id_sucursal: {
         required,
      },
   },
   data: function () {
      return {
         id_sucursal: null,

         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               
               let res = await this.$store.dispatch("authDevice", {
                  id_sucursal: this.id_sucursal,
               });

               if (res.key) {
                  localStorage.setItem("key", res.key);
                  this.$emit("save");
                  this.close();
               } else {
                  throw "Error al autorizar dispositivo";
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.Show(error.message);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: async function () {
         try {
            this.dialog = true;
            this.isloading = true;
            await this.$store.dispatch("getSucursalesCombo");
         } catch (error) {
            this.$refs.error_noti.Show(error.message);
         } finally {
            this.isloading = false;
         }
      },
   },
   computed: {
      id_sucursalErrors() {
         const errors = [];
         if (!this.$v.id_sucursal.$dirty) return errors;
         !this.$v.id_sucursal.required && errors.push("Requerido");
         return errors;
      },
   },
};
</script>
