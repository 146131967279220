<template>
   <div>
      <v-dialog v-model="dialog"  width="max-content">
         <template v-slot:activator="{ on, attrs }">
            <v-chip :color="local" v-bind="attrs" v-on="on" class="pa-4 elevation-1"></v-chip>
         </template>
         <v-card>
            <v-color-picker v-model="local" dot-size="25" mode="hexa" swatches-max-height="200"></v-color-picker>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>

export default {
   props: {
      value: {
         type: String,
      }
   },
   data: () => {
      return {
         dialog: false,
         local: "",
      };
   },
   created() {
      this.local = this.value;
      this.$emit("input", this.local);
   },
   watch: {
      value: function () {
         this.local = this.value;
      },
      dialog: function () {
         this.$emit("input", this.local);
      },
   },
};
</script>
