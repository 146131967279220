<template>
   <v-expand-transition>
      <v-alert v-model="show" dismissible v-show="show" :type="type"> {{ message + " " + append }} </v-alert>
   </v-expand-transition>
</template>

<script>
export default {
   props: {
      message: String,
      type: {
         type: String,
         default: "error",
      },
      duration: {
         type: Number,
         default: 5000,
      },
   },
   data: function () {
      return {
         show: false,
         timeout: null,
         append: "",
      };
   },
   methods: {
      async Show(msj) {
         this.show = true;
         if (msj) {
            if (typeof msj === "string" || msj instanceof String) {
               this.append = msj;
            } else {
               this.append = JSON.stringify(msj);
            }
         }
         this.append = this.append.substring(0,100);
         this.timeout = await setTimeout(() => {
            this.show = false;
         }, this.duration);
         return true;
      },
      close() {
         clearTimeout(this.timeout);
      },
   },
   watch: {
      show: function () {
         if (this.show == false) {
            this.close();
         }
      },
   },
};
</script>

<style scoped>
.v-alert {
   position: fixed;
   left: 50%;
   bottom: 50px;
   transform: translate(-50%, 0);
   max-width: 400px;
   width: max-content;
   z-index: 999;
}
</style>