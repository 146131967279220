<template>
   <v-dialog v-model="show" :max-width="maxWidth">
      <v-card>
         <v-card-title style="word-break: unset;">{{ message }}</v-card-title>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" text @click="dialogResult_Cancel">Cancelar</v-btn>
            <v-btn color="error" text @click="dialogResult_OK">Aceptar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
export default {
   props: {
      message: String,
      maxWidth: {
         type: Number,
         default: 290
      }
   },
   data: () => {
      return {
         show: false,
      };
   },
   methods: {
      dialogResult_OK: function () {
         this.show = false;
         this.$emit("result_ok");
      },
      dialogResult_Cancel: function () {
         this.show = false;
         this.$emit("result_cancel");
      },
      Show: function () {
         this.show = true;
      },
   },
};
</script>
